import {
  VaultType,
  VaultFilterType,
  VaultCategoryType,
  SpotRedemptionType,
  SubscriptionFilterType,
  DerivativeRedemptionType
} from '../../../types'

export default {
  vault: {
    banner: {
      exploreVault: {
        title: 'Explore Vaults',
        description:
          'Jump start your crypto portfolio with smart contract-enabled trading strategies. Subscribe to any Mito vault to begin trading on autopilot and to activate your earnings.'
      },
      whatsNew: {
        swapFeature: 'Swap Feature',
        title: "What's {new}"
      }
    },
    common: {
      APY: 'APY',
      APYV3: 'APY V3',
      APYUE: 'APYUE',
      ethena: 'Ethena',
      pnl: 'pnl',
      tvl: 'TVL',
      fees: 'Fees',
      vault: 'Vault',
      price: 'Price',
      total: 'Total',
      liquidity: 'Liquidity',
      addLiquidity: 'Add Liquidity',
      add: '+Add',
      removeLiquidity: 'Remove Liquidity',
      remove: '-Remove',
      redeem: 'Redeem',
      update: 'Update',
      subscribe: 'Subscribe',
      percentage: 'Percentage',
      tokenPrice: 'Token price',
      performance: 'Performance',
      doubleSided: 'Double sided',
      singleSided: 'Single sided',
      backToVault: 'Back to Vault',
      subscriptions: 'Subscriptions',
      backToVaults: 'Back to Vaults',
      viewTransaction: 'View Transaction',
      redemptionLocked: 'Redemption locked',
      [DerivativeRedemptionType.PositionAndQuote]: 'Position and Quote',
      [SpotRedemptionType.BaseOnly]: 'Base only',
      [SpotRedemptionType.QuoteOnly]: 'Quote only',
      [SpotRedemptionType.BaseAndQuote]: 'Base and Quote',
      [SpotRedemptionType.FixedBaseAndQuote]: 'Fixed Base and Quote',
      [SpotRedemptionType.VariableBaseAndQuote]: 'Variable Base and Quote',
      lockedFor: 'Locked for {countdown}'
    },
    tooltip: {
      USDE: 'LP token holders are eligible for 15x Sats on {link}',
      APYCombine: 'APY combines performance yields and reward yields',
      StakingBreakdown:
        'Including vault tokens staked, unstaking and ready to be withdrawn'
    },
    subscription: {
      less: 'Less',
      more: 'More',
      available: 'Available',
      totalValue: 'Total Value',
      noData: {
        vaultsPage: 'Vaults page',
        noHoldings: 'You have no holdings',
        description: "You don't have any vault holdings at the moment.",
        link: 'View and subscribe on {link}'
      }
    },
    table: {
      '24h': '24H',
      spot: 'Spot',
      perp: 'Perp',
      estAPY: 'Est. APY',
      title: 'All Vaults',
      strategy: 'Strategy',
      empty: 'No vaults found',
      totalValue: 'Total Value',
      tradeAssets: 'Trade assets',
      viewPortfolio: 'View Portfolio'
    },
    toast: {
      vaultNotFound: 'Vault not found'
    },
    overview: {
      assets: 'Assets',
      title: 'Overview',
      spotAssets: 'Spot Assets',
      myHoldings: 'My Holdings',
      strategyInfo: 'StrategyInfo',
      unrealizedPnL: 'Unrealized PnL',
      derivativeAssets: 'Derivative Assets',
      perpetualPosition: 'Perpetual Position',
      totalValueLocked: 'Total Value Locked (TVL)',
      averageMonthlyReturn: 'Average Monthly Return (Base)',
      totalReturnAsOf: 'Total return as of {date}',
      vaultTokenPrice: 'Vault Token ({vault}) Share Price'
    },
    strategyInfo: {
      info: 'Info',
      links: 'Links',
      title: 'Strategy Info',
      infoLinks: 'Info/Links',
      vaultContract: 'Vault contract',
      description: 'Strategy description',
      baseStrategyCode: 'Base strategy code',
      viewVaultStrategyParameters: 'View vault strategy parameters',
      details: {
        activeMarket: 'Active Market',
        strategyCode: 'Strategy Code',
        inceptionTime: 'Inception Time',
        vaultIdentifier: 'Vault Identifier',
        strategyProvider: 'Strategy Provider'
      }
    },
    liquidity: {
      zeroValueDisplay: '< 0.01%',
      empty: 'No liquidity found'
    },
    parameters: {
      title: 'Parameters',
      titleInfo: 'Vault Info',
      titleOffChain: 'Off-chain Strategy Parameters',
      titleBase: 'On-chain Strategy Parameters (Base)',
      titleMarketMaking: 'On-chain Strategy Parameters (Market Making)',
      titleVault: 'On-chain Strategy Parameters (Vault)',
      info: {
        adminAddress: 'Admin Address',
        creatorAddress: 'Creator address',
        subaccountId: 'Subaccount Id',
        masterAddress: 'Master Address',
        vaultAddress: 'Vault Address'
      },
      config: {
        marketId: 'Market Id',
        feeRecipient: 'Fee Recipient'
      }
    },
    time: {
      allTime: 'All-time',
      oneDay: '1 Day',
      oneMonth: '1 Month',
      oneWeek: '1 Week',
      oneYear: '1 Year',
      sixMonths: '6 Months',
      threeMonths: '3 Months',
      threeYears: '3 Years',
      yesterday: 'Yesterday',
      last24hrs: 'Last 24 hrs',
      pnl1Day: 'P&L (1D)'
    },
    card: {
      maxCap: 'Max Cap',
      strategy: 'Strategy',
      price24H: 'Price (24H)',
      tradeAssets: 'Trade assets',
      viewDetails: 'View details',
      activeMarket: 'Active Market'
    },
    strategy: {
      [VaultCategoryType.All]: {
        title: 'All Vaults'
      },
      [VaultCategoryType.Managed]: {
        title: 'Managed Vaults',
        type: 'Managed'
      },
      [VaultCategoryType.Automated]: {
        title: 'Automated Vaults',
        type: 'Automated'
      },
      [VaultType.ASMMSpot]: {
        title: 'ASMM',
        tooltip: 'Utilize the Avellaneda-Stokikov market making strategy',
        description:
          'Mito Spot ASMM trading strategy is based on the algorithm created by renown high frequency trading thought leaders Marco Avellaneda and Sasha Stoikov, better known as the Avellaneda-Stoikov model. This model is based on the vault’s inventory position and risk, and is widely used by both TradFi and crypto trading firms.',
        type: 'On-chain Market Maker'
      },
      [VaultType.ASMMPerp]: {
        title: 'ASMM',
        tooltip: 'Utilize the Avellaneda-Stokikov market making strategy',
        description:
          'Mito Perp ASMM trading strategy is based on the algorithm created by renown high frequency trading thought leaders Marco Avellaneda and Sasha Stoikov, better known as the Avellaneda-Stoikov model. This model is based on the vault’s inventory position and risk, and is widely used by both TradFi and crypto trading firms.',
        type: 'On-chain Market Maker'
      },
      [VaultType.CPMM]: {
        title: 'CPMM',
        tooltip:
          'Constant Product Market Maker is a strategy based on the formula x*y=k',
        description:
          'Mito CPMM trading strategy is based on the Constant Product Market Maker model (known for its formula x*y=k), which involves providing liquidity to a particular trading pair by holding an equal value of the two assets in the pair. This strategy is what the popular Uniswap v2 is based on.\n\nIn practice, the strategy will assume the price of the underlying asset in the vault based on the ratio of its inventory. Subsequently, limit orders will be created around this assumed price on the powerful Injective orderbook, which means the vault is opened to traders across the Injective ecosystem and not just on Mito. This strategy earns trading revenue from the spread between its buy and sell orders.',
        type: 'On-chain Market Maker'
      },
      [VaultType.OffChain]: {
        title: 'Off chain',
        tooltip: 'TBD',
        description: 'TBD',
        type: 'Off Chain'
      }
    },
    activities: {
      title: 'Activities'
    },
    vaultTrades: {
      title: 'Trades',
      tradeHistory: 'Trade History',
      table: {
        time: 'Time',
        market: 'Market',
        side: 'Side',
        total: 'Total'
      }
    },

    vaultOrders: {
      title: 'Orders',
      orders: {
        title: 'Orders',
        table: {
          pair: 'Pair',
          side: 'Side',
          price: 'Price',
          unfilled: 'Unfilled',
          filled: 'Filled',
          leverage: 'Leverage',
          total: 'Total',
          notAvailableNotApplicable: 'N/A'
        }
      },
      position: {
        title: 'Position',
        table: {
          pair: 'Pair',
          side: 'Side',
          long: 'Long',
          short: 'Short',
          price: 'Price',
          total: 'Total',
          margin: 'Margin',
          leverage: 'Leverage',
          entry_mark: 'Entry/Mark',
          unrealizedPnl: 'Unrealized PNL',
          liquidationPrice: 'Est. Liq Price'
        }
      },
      orderbook: {
        price: 'Price',
        total: 'Total'
      }
    },

    vaultActions: {
      table: {
        time: 'Time',
        assets: 'Assets',
        lpAmount: 'LP Amount',
        addedLiquidity: 'Added liquidity',
        removeLiquidity: 'Removed liquidity'
      }
    },

    subscribe: {
      title: 'Add liquidity to {vault}',
      description:
        'Add liquidity to a vault in a few clicks and let the trading strategy do its work.',
      baseAmount: 'Base amount',
      quoteAmount: 'Quote amount',
      marginRatio: 'Margin ratio',
      assetRatio: 'Asset Ratio for Vault',
      selectSource: 'Select source',
      selectSourceTooltip: 'Select source tooltip',
      source: {
        bank: 'Injective',
        ethereum: 'Ethereum'
      },
      amountExceedsNotional: 'Total amount exceeds vault notional cap',
      vaultTokenReceived: 'No. of vaultToken received (est)',
      confirmation: {
        title: 'Summary',
        fromWallet: 'From wallet',
        toVault: 'To vault',
        subscribeAmount: 'Subscribe amount',
        vaultTokenReceived: 'No. of vaultToken received',
        successfulTitle: 'Added liquidity!',
        successToast: 'You have successfully added liquidity to {vault}.',
        successToastWithLpAmount:
          'You have successfully added liquidity to {vault} and received {amount}.',
        baseOnlyDeduction:
          '{quoteAmount} {quoteSymbol} were deducted from your wallet.',
        quoteOnlyDeduction:
          '{baseAmount} {baseSymbol} were deducted from your wallet.',
        baseAndQuoteDeduction:
          '{baseAmount} {baseSymbol} and {quoteAmount} {quoteSymbol} were deducted from your wallet.'
      }
    },
    redeem: {
      title: 'Remove liquidity from {vault}',
      description: 'Remove liquidity from the vault to withdraw your funds.',
      youBurn: 'You burn',
      fundTokenReceived: 'No. of {symbol} received (est)',
      requestRedemption: 'Request Redemption',
      redeemableLockedLpTokens: 'Redeemable locked LP tokens',
      confirmation: {
        toWallet: 'To wallet',
        title: 'Summary',
        fromVault: 'From Vault',
        redeemFundsTo: 'Redeem funds to',
        youWillReceive: 'You will receive',
        amountReceiving: 'Amount receiving',
        successfulTitle: 'Removed liquidity!',
        successToastWithRedeemedAmount:
          'Removed {lpAmount} {lpSymbol} for {coins}.',
        successToast: 'Removed {lpAmount} {lpSymbol} from {vault}'
      }
    },
    filters: {
      title: 'Filters',
      showAll: 'Show all',
      allMarkets: 'All Markets',
      clearFilters: 'Clear filters',
      allStrategies: 'All Strategies',
      showAllVaults: 'Show all vaults',
      showMyVaultOnly: 'Show my vault only',
      isPositiveAPYOnly: 'Best performing vaults',
      [VaultFilterType.APYLow]: 'APY low to high',
      [VaultFilterType.TVLLow]: 'TVL low to high',
      [VaultFilterType.TVLHigh]: 'TVL high to low',
      [VaultFilterType.APYHigh]: 'APY high to low',
      [SubscriptionFilterType.StakeLow]: 'Total staked low to high',
      [SubscriptionFilterType.StakeHigh]: 'Total staked high to low',
      [SubscriptionFilterType.TotalValueLow]: 'Total value low to high',
      [SubscriptionFilterType.TotalValueHigh]: 'Total value high to low',
      [SubscriptionFilterType.TotalAvailableLow]: 'Available low to high',
      [SubscriptionFilterType.TotalAvailableHigh]: 'Available high to low'
    },
    ninjaPass: {
      title: 'Ninja Pass Exclusive Period',
      publicAccessIn: 'Public access in:'
    },
    experimentalMessage: {
      title: 'Experimental vault',
      cta: 'I Understand',
      doNotShowCta: 'Do not show this confirmation again',
      termsAndConditions: 'Terms and Conditions',
      description:
        'You are accessing a vault available on Injective but not listed on Mito. Please check whether the vault address is the one you would like to access.',
      description2:
        'By proceeding, you acknowledge that you have read, that you agree to, and that you are bound by the Mito {link} as to any use you make of Mito.'
    },
    permissionlessMarketRedirect: {
      title: 'You are leaving Mito to access Helix',
      description:
        'Be advised that the assets available on this platform are subject to market volatility and risk. We recommend performing thorough research before proceeding with any transactions.',
      cta: 'Buy {baseSymbol} on Helix',
      backCta: 'Back to Mito'
    }
  }
}
