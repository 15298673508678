import { GEO_IP_RESTRICTIONS_ENABLED } from '@shared/utils/constant'
import { restrictedCountries } from '@/app/json'

export const restrictedMitoCountries = ['CA', 'GB']

export const isCountryFullyRestricted = (country: string) =>
  GEO_IP_RESTRICTIONS_ENABLED && restrictedCountries.includes(country)

export const isCountryRestricted = (country: string) =>
  GEO_IP_RESTRICTIONS_ENABLED &&
  [...restrictedCountries, ...restrictedMitoCountries].includes(country)
