import {
  Network,
  isDevnet,
  isMainnet,
  isTestnet,
  getCw20SwapContractForNetwork
} from '@injectivelabs/networks'

export const NETWORK: Network =
  (import.meta.env.VITE_NETWORK as Network) || Network.Devnet
export const IS_DEVNET = isDevnet(NETWORK)
export const IS_TESTNET = isTestnet(NETWORK)
export const IS_MAINNET = isMainnet(NETWORK)
export const IS_STAGING = NETWORK === Network.Staging

export const IS_PRODUCTION: boolean = process.env.NODE_ENV === 'production'
export const IS_DEVELOPMENT: boolean = process.env.NODE_ENV === 'development'

const devnetEnv = {
  asmmSpotCode: 4,
  asmmPerpCode: 3,
  cpmmContractCode: 5,
  vestingContractCode: 16,
  swapContractAddress: 'inj1qk00h5atutpsv900x202pxx42npjr9thrzhgxn',
  voteContractAddress: '',
  stakingContractAddress: 'inj1qa4hswlcjmttulj0q9qa46jf64f93pecxar5pu',
  allocatorContractAddress: 'inj17r3faruez552kdxy0lsjydmj0nu22mxaz8ad6e',
  mitoMasterContractAddress: 'inj1wug8sewp6cedgkmrmvhl3lf3tulagm9h5uhctd',
  launchpadFactoryContractAddress: 'inj1qp35fcj0v9u3trhaps5v9q0lc42t4m6azfejrz',
  masterContractOwnerAddress: 'inj17gkuet8f6pssxd8nycm3qr9d9y699rupv6397z',
  injPythPriceFeedId:
    '0x7a5bc1d2b56ad029048cd63964b3ad2776eadf812edc1a43a31406cb54bff592',
  mixpanelKey: ''
}

const testnetEnv = {
  asmmSpotCode: 8260,
  asmmPerpCode: 8261,
  cpmmContractCode: 9212,
  vestingContractCode: 7668,
  swapContractAddress: getCw20SwapContractForNetwork(NETWORK),
  voteContractAddress: 'inj1skdlyqq32vd9e8rk3wukmadupg8ky3nakf6hu0',
  stakingContractAddress: 'inj17yk350dhnct9pc4pvswn02243wg8h6x65pr5xt',
  allocatorContractAddress: 'inj1q27kwdcxmuzxs9vzjtlzprx2l4qzwd4tgg4wzj',
  mitoMasterContractAddress: 'inj174efvalr8d9muguudh9uyd7ah7zdukqs9w4adq',
  launchpadFactoryContractAddress: 'inj10z08evj0tt33jk008tz27c3a3g3pfj5nypfkwg',
  masterContractOwnerAddress: 'inj17gkuet8f6pssxd8nycm3qr9d9y699rupv6397z',
  injPythPriceFeedId:
    '0x2d9315a88f3019f8efa88dfe9c0f0843712da0bac814461e27733f6b83eb51b3',
  mixpanelKey: import.meta.env.VITE_MIXPANEL_KEY_DEV as string
}

const mainnetEnv = {
  asmmSpotCode: 999, // not deployed yet
  asmmPerpCode: 999, // not deployed yet
  cpmmContractCode: 1365,
  vestingContractCode: 535,
  voteContractAddress: '',
  swapContractAddress: 'inj1j5mr2hmv7y2z7trazganj75u8km8jvdfuxncsp',
  stakingContractAddress: 'inj1gtze7qm07nky47n7mwgj4zatf2s77xqvh3k2n8',
  allocatorContractAddress: 'inj1vswsdqx5clnxv9vvlv4kuvjwtqty92mtyarw4t',
  mitoMasterContractAddress: 'inj1vcqkkvqs7prqu70dpddfj7kqeqfdz5gg662qs3',
  launchpadFactoryContractAddress: 'inj1hp9u735x3awsufrs5gmqescx8a7yrln3tljyke',
  masterContractOwnerAddress: 'inj1twlrzy4l0pzpka24apfhclpa4t4x7tg9flv7ql',
  injPythPriceFeedId:
    '0x7a5bc1d2b56ad029048cd63964b3ad2776eadf812edc1a43a31406cb54bff592',
  mixpanelKey: import.meta.env.VITE_MIXPANEL_KEY as string
}

const env = IS_MAINNET ? mainnetEnv : IS_TESTNET ? testnetEnv : devnetEnv

const MITO_DEVNET_API_ENDPOINT = 'https://devnet.api.ninja.injective.dev'
const MITO_TESTNET_API_ENDPOINT =
  'https://k8s.testnet.mito.grpc-web.injective.network'
const MITO_MAINNET_API_ENDPOINT =
  'https://k8s.global.mainnet.mito.grpc-web.injective.network'

export const MITO_API_ENDPOINTS: Record<Network, string> = {
  [Network.Local]: MITO_DEVNET_API_ENDPOINT,
  [Network.Devnet]: MITO_DEVNET_API_ENDPOINT,
  [Network.Devnet1]: 'https://devnet-1.api.ninja.injective.dev',
  [Network.Devnet2]: 'https://devnet-2.api.ninja.injective.dev',
  [Network.Devnet3]: 'https://devnet-3.api.ninja.injective.dev',
  [Network.Testnet]: MITO_TESTNET_API_ENDPOINT,
  [Network.TestnetSentry]: MITO_TESTNET_API_ENDPOINT,
  [Network.TestnetOld]: MITO_TESTNET_API_ENDPOINT,
  [Network.TestnetK8s]: MITO_TESTNET_API_ENDPOINT,
  [Network.Staging]: 'https://staging.api.mito.injective.network',
  [Network.Mainnet]: MITO_MAINNET_API_ENDPOINT,
  [Network.Internal]: MITO_MAINNET_API_ENDPOINT,
  [Network.MainnetLB]: MITO_MAINNET_API_ENDPOINT,
  [Network.MainnetK8s]: MITO_MAINNET_API_ENDPOINT,
  [Network.MainnetOld]: MITO_MAINNET_API_ENDPOINT,
  [Network.MainnetSentry]: MITO_MAINNET_API_ENDPOINT
}

export const MITO_API_ENDPOINT =
  (import.meta.env.VITE_MITO_API_ENDPOINT as string) ||
  MITO_API_ENDPOINTS[NETWORK] ||
  MITO_DEVNET_API_ENDPOINT

export const EXPRESS_SERVER_API = import.meta.env
  .VITE_EXPRESS_SERVER_API as string

export const CPMM_CONTRACT_CODE = env.cpmmContractCode
export const ASMM_SPOT_CONTRACT_CODE = env.asmmSpotCode
export const ASMM_PERP_CONTRACT_CODE = env.asmmPerpCode
export const VESTING_CONTRACT_CODE = env.vestingContractCode

export const SWAP_CONTRACT_ADDRESS = env.swapContractAddress
export const VOTE_CONTRACT_ADDRESS = env.voteContractAddress
export const STAKING_CONTRACT_ADDRESS = env.stakingContractAddress
export const ALLOCATOR_CONTRACT_ADDRESS = env.allocatorContractAddress
export const MITO_MASTER_CONTRACT_ADDRESS = env.mitoMasterContractAddress
export const LAUNCHPAD_FACTORY_CONTRACT_ADDRESS =
  env.launchpadFactoryContractAddress

export const MASTER_CONTRACT_OWNER_ADDRESS = env.masterContractOwnerAddress
export const INJ_PYTH_PRICE_FEED_ID = env.injPythPriceFeedId
export const MIXPANEL_KEY = env.mixpanelKey

export const GALXE_CREDENTIAL_ID = '338953685988712448'

export const MAINTENANCE_ENABLED: boolean =
  import.meta.env.VITE_MAINTENANCE_ENABLED === 'true'
