import { RouteLocationNormalized } from 'vue-router'
import { pageTitle } from '@/app/data/app'
import { IS_MAINNET } from '@/app/utils/constant'
import { isCountryFullyRestricted, isCountryRestricted } from '@/app/data/geoip'
import { walletConnectedRequiredRoutes } from '@/app/data/route'
import { Page, Modal, MainPages, VaultSubPage, PortfolioSubPage } from '@/types'

const STREAMS_TO_PERSIST = [
  'streamAccountPortfolio',
  'streamStakingRewardsByAccount',
  'streamVaultHolderSubscriptions'
]
const ROUTES_TO_PERSIST_STREAMS = [MainPages.Portfolio, VaultSubPage.Overview]

export default defineNuxtRouteMiddleware((to, from) => {
  const modalStore = useSharedModalStore()
  const sharedGeoStore = useSharedGeoStore()
  const sharedModalStore = useSharedModalStore()

  if (
    to.name !== MainPages.Index &&
    isCountryFullyRestricted(sharedGeoStore.country)
  ) {
    sharedModalStore.openModal(Modal.GeoRestricted)

    return navigateTo({ name: MainPages.Index })
  }

  if (
    ![
      MainPages.Index,
      MainPages.Portfolio,
      ...Object.values(PortfolioSubPage)
    ].includes(to.name as MainPages) &&
    isCountryRestricted(sharedGeoStore.country)
  ) {
    setTimeout(() => {
      sharedModalStore.openModal(Modal.GeoRestricted)
    }, 100)

    return navigateTo({ name: MainPages.Index })
  }

  if (
    IS_MAINNET &&
    [MainPages.Vote, MainPages.Missions].includes(to?.name as MainPages)
  ) {
    return navigateTo({
      name: MainPages.Launchpad
    })
  }

  handleDevMode(to)
  handlePageTitle(to)
  handleRouteChange(to)
  handleCancelStreams(to, from)

  modalStore.closeAll()
})

function handleDevMode(to: RouteLocationNormalized) {
  const appStore = useAppStore()

  if (to.query?.devMode === 'true') {
    appStore.$patch({
      devMode: true
    })
  }

  if (to.query?.owner === 'true') {
    appStore.$patch({
      owner: true
    })
  }
}

function handlePageTitle(to: RouteLocationNormalized) {
  const title = pageTitle[to.name as string]

  useHead({ title: title ? `Mito | ${title}` : `Mito` })
}

function handleCancelStreams(
  to: RouteLocationNormalized,
  from: RouteLocationNormalized
) {
  const appStore = useAppStore()
  const toName = (to.name || '') as string
  const fromName = (from.name || '') as string

  const isSubPageChange = ROUTES_TO_PERSIST_STREAMS.some((route) => {
    return toName.includes(route) && fromName.includes(route)
  })

  if (isSubPageChange) {
    return
  }

  appStore.cancelAllStreams(STREAMS_TO_PERSIST)
}

function handleRouteChange(to: RouteLocationNormalized) {
  const sharedWalletStore = useSharedWalletStore()

  if (
    sharedWalletStore.isUserConnected ||
    !walletConnectedRequiredRoutes.includes(to.name as Page)
  ) {
    return
  }

  return navigateTo({
    name: MainPages.Vaults
  })
}
