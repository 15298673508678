
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as createQjkY3KUJcEMeta } from "/root/injective/mito-ui/pages/cpmm/create.vue?macro=true";
import { default as envad7nCgl158Meta } from "/root/injective/mito-ui/pages/env.vue?macro=true";
import { default as index6YOxd2gaXgMeta } from "/root/injective/mito-ui/pages/index.vue?macro=true";
import { default as indexLOnWEsP7lBMeta } from "/root/injective/mito-ui/pages/launchpad/[ido]/index.vue?macro=true";
import { default as resultLUNnnhuRTGMeta } from "/root/injective/mito-ui/pages/launchpad/[ido]/result.vue?macro=true";
import { default as subscribersNqYL8rycLzMeta } from "/root/injective/mito-ui/pages/launchpad/[ido]/subscribers.vue?macro=true";
import { default as subscription9iIqcHPRPiMeta } from "/root/injective/mito-ui/pages/launchpad/[ido]/subscription.vue?macro=true";
import { default as whitelistUP5yxEyjlMMeta } from "/root/injective/mito-ui/pages/launchpad/[ido]/whitelist.vue?macro=true";
import { default as _91ido_93XwX2heOHrhMeta } from "/root/injective/mito-ui/pages/launchpad/[ido].vue?macro=true";
import { default as indextIcsLKLEtnMeta } from "/root/injective/mito-ui/pages/launchpad/index.vue?macro=true";
import { default as maintenanceu58qdYHMLVMeta } from "/root/injective/mito-ui/pages/maintenance.vue?macro=true";
import { default as gaugePbihmDY4tNMeta } from "/root/injective/mito-ui/pages/manage/gauge.vue?macro=true";
import { default as index1SS6UcikH1Meta } from "/root/injective/mito-ui/pages/manage/index.vue?macro=true";
import { default as launchpadYP9JclY5ZhMeta } from "/root/injective/mito-ui/pages/manage/launchpad.vue?macro=true";
import { default as marketf4RrsPFLrHMeta } from "/root/injective/mito-ui/pages/manage/market.vue?macro=true";
import { default as projectsHfvDd49kkVMeta } from "/root/injective/mito-ui/pages/manage/projects.vue?macro=true";
import { default as managenT7NHGPZtCMeta } from "/root/injective/mito-ui/pages/manage.vue?macro=true";
import { default as indexBKtRlIg68cMeta } from "/root/injective/mito-ui/pages/missions/index.vue?macro=true";
import { default as leaderboardbDh86fA58zMeta } from "/root/injective/mito-ui/pages/missions/leaderboard.vue?macro=true";
import { default as progressu7U4fsBKf3Meta } from "/root/injective/mito-ui/pages/missions/progress.vue?macro=true";
import { default as missions4KGQoBqqJUMeta } from "/root/injective/mito-ui/pages/missions.vue?macro=true";
import { default as indexUYIfaccfJ3Meta } from "/root/injective/mito-ui/pages/portfolio/activities/index.vue?macro=true";
import { default as rewards7Dd1JaOa6hMeta } from "/root/injective/mito-ui/pages/portfolio/activities/rewards.vue?macro=true";
import { default as swap_45historySqvRR7vR5BMeta } from "/root/injective/mito-ui/pages/portfolio/activities/swap-history.vue?macro=true";
import { default as activities94moQsTY6yMeta } from "/root/injective/mito-ui/pages/portfolio/activities.vue?macro=true";
import { default as indexXzBmJNBbVaMeta } from "/root/injective/mito-ui/pages/portfolio/index.vue?macro=true";
import { default as portfolioIaUXyjS86KMeta } from "/root/injective/mito-ui/pages/portfolio.vue?macro=true";
import { default as privacy_45policyYXl1v5L8xsMeta } from "/root/injective/mito-ui/pages/privacy-policy.vue?macro=true";
import { default as rewards7yhp1MZ9X8Meta } from "/root/injective/mito-ui/pages/rewards.vue?macro=true";
import { default as allocator2e8NKIn2LHMeta } from "/root/injective/mito-ui/pages/settings/allocator.vue?macro=true";
import { default as cpmm_45allowanceiCCgD5TtE4Meta } from "/root/injective/mito-ui/pages/settings/cpmm-allowance.vue?macro=true";
import { default as indexWxmUuBUQkIMeta } from "/root/injective/mito-ui/pages/settings/index.vue?macro=true";
import { default as stakingijkV7ioXRfMeta } from "/root/injective/mito-ui/pages/settings/staking.vue?macro=true";
import { default as settingshcfbUKwGutMeta } from "/root/injective/mito-ui/pages/settings.vue?macro=true";
import { default as swapEcH12sD09uMeta } from "/root/injective/mito-ui/pages/swap.vue?macro=true";
import { default as terms_45of_45usezCDOtTU1KrMeta } from "/root/injective/mito-ui/pages/terms-of-use.vue?macro=true";
import { default as indexse1AuHM4hIMeta } from "/root/injective/mito-ui/pages/vault/[vault]/activities/index.vue?macro=true";
import { default as ordersSo2Vnt1rcfMeta } from "/root/injective/mito-ui/pages/vault/[vault]/activities/orders.vue?macro=true";
import { default as tradesZMXW6lzDCaMeta } from "/root/injective/mito-ui/pages/vault/[vault]/activities/trades.vue?macro=true";
import { default as activities8OpnPfBSn4Meta } from "/root/injective/mito-ui/pages/vault/[vault]/activities.vue?macro=true";
import { default as configUvdJFnfcLIMeta } from "/root/injective/mito-ui/pages/vault/[vault]/config.vue?macro=true";
import { default as indexKM14Zbngs0Meta } from "/root/injective/mito-ui/pages/vault/[vault]/index.vue?macro=true";
import { default as infoLCG300Py8qMeta } from "/root/injective/mito-ui/pages/vault/[vault]/info.vue?macro=true";
import { default as liquidityB0SVz5taEZMeta } from "/root/injective/mito-ui/pages/vault/[vault]/liquidity.vue?macro=true";
import { default as rewardseXn3p5Gsd7Meta } from "/root/injective/mito-ui/pages/vault/[vault]/rewards.vue?macro=true";
import { default as _91vault_93gn0rprySalMeta } from "/root/injective/mito-ui/pages/vault/[vault].vue?macro=true";
import { default as vaults2L3Qdm0nTJMeta } from "/root/injective/mito-ui/pages/vaults.vue?macro=true";
import { default as voteJAa0CtZPtGMeta } from "/root/injective/mito-ui/pages/vote.vue?macro=true";
export default [
  {
    name: "cpmm-create",
    path: "/cpmm/create",
    component: () => import("/root/injective/mito-ui/pages/cpmm/create.vue")
  },
  {
    name: "env",
    path: "/env",
    meta: envad7nCgl158Meta || {},
    component: () => import("/root/injective/mito-ui/pages/env.vue")
  },
  {
    name: "index",
    path: "/",
    meta: index6YOxd2gaXgMeta || {},
    component: () => import("/root/injective/mito-ui/pages/index.vue")
  },
  {
    name: _91ido_93XwX2heOHrhMeta?.name,
    path: "/launchpad/:ido()",
    component: () => import("/root/injective/mito-ui/pages/launchpad/[ido].vue"),
    children: [
  {
    name: "launchpad-ido",
    path: "",
    component: () => import("/root/injective/mito-ui/pages/launchpad/[ido]/index.vue")
  },
  {
    name: "launchpad-ido-result",
    path: "result",
    component: () => import("/root/injective/mito-ui/pages/launchpad/[ido]/result.vue")
  },
  {
    name: "launchpad-ido-subscribers",
    path: "subscribers",
    component: () => import("/root/injective/mito-ui/pages/launchpad/[ido]/subscribers.vue")
  },
  {
    name: "launchpad-ido-subscription",
    path: "subscription",
    component: () => import("/root/injective/mito-ui/pages/launchpad/[ido]/subscription.vue")
  },
  {
    name: "launchpad-ido-whitelist",
    path: "whitelist",
    component: () => import("/root/injective/mito-ui/pages/launchpad/[ido]/whitelist.vue")
  }
]
  },
  {
    name: "launchpad",
    path: "/launchpad",
    component: () => import("/root/injective/mito-ui/pages/launchpad/index.vue")
  },
  {
    name: "maintenance",
    path: "/maintenance",
    meta: maintenanceu58qdYHMLVMeta || {},
    component: () => import("/root/injective/mito-ui/pages/maintenance.vue")
  },
  {
    name: managenT7NHGPZtCMeta?.name,
    path: "/manage",
    component: () => import("/root/injective/mito-ui/pages/manage.vue"),
    children: [
  {
    name: "manage-gauge",
    path: "gauge",
    meta: gaugePbihmDY4tNMeta || {},
    component: () => import("/root/injective/mito-ui/pages/manage/gauge.vue")
  },
  {
    name: "manage",
    path: "",
    component: () => import("/root/injective/mito-ui/pages/manage/index.vue")
  },
  {
    name: "manage-launchpad",
    path: "launchpad",
    component: () => import("/root/injective/mito-ui/pages/manage/launchpad.vue")
  },
  {
    name: "manage-market",
    path: "market",
    component: () => import("/root/injective/mito-ui/pages/manage/market.vue")
  },
  {
    name: "manage-projects",
    path: "projects",
    component: () => import("/root/injective/mito-ui/pages/manage/projects.vue")
  }
]
  },
  {
    name: missions4KGQoBqqJUMeta?.name,
    path: "/missions",
    component: () => import("/root/injective/mito-ui/pages/missions.vue"),
    children: [
  {
    name: "missions",
    path: "",
    component: () => import("/root/injective/mito-ui/pages/missions/index.vue")
  },
  {
    name: "missions-leaderboard",
    path: "leaderboard",
    component: () => import("/root/injective/mito-ui/pages/missions/leaderboard.vue")
  },
  {
    name: "missions-progress",
    path: "progress",
    component: () => import("/root/injective/mito-ui/pages/missions/progress.vue")
  }
]
  },
  {
    name: portfolioIaUXyjS86KMeta?.name,
    path: "/portfolio",
    component: () => import("/root/injective/mito-ui/pages/portfolio.vue"),
    children: [
  {
    name: activities94moQsTY6yMeta?.name,
    path: "activities",
    component: () => import("/root/injective/mito-ui/pages/portfolio/activities.vue"),
    children: [
  {
    name: "portfolio-activities",
    path: "",
    component: () => import("/root/injective/mito-ui/pages/portfolio/activities/index.vue")
  },
  {
    name: "portfolio-activities-rewards",
    path: "rewards",
    component: () => import("/root/injective/mito-ui/pages/portfolio/activities/rewards.vue")
  },
  {
    name: "portfolio-activities-swap-history",
    path: "swap-history",
    component: () => import("/root/injective/mito-ui/pages/portfolio/activities/swap-history.vue")
  }
]
  },
  {
    name: "portfolio",
    path: "",
    component: () => import("/root/injective/mito-ui/pages/portfolio/index.vue")
  }
]
  },
  {
    name: "privacy-policy",
    path: "/privacy-policy",
    meta: privacy_45policyYXl1v5L8xsMeta || {},
    component: () => import("/root/injective/mito-ui/pages/privacy-policy.vue")
  },
  {
    name: "rewards",
    path: "/rewards",
    component: () => import("/root/injective/mito-ui/pages/rewards.vue")
  },
  {
    name: settingshcfbUKwGutMeta?.name,
    path: "/settings",
    meta: settingshcfbUKwGutMeta || {},
    component: () => import("/root/injective/mito-ui/pages/settings.vue"),
    children: [
  {
    name: "settings-allocator",
    path: "allocator",
    component: () => import("/root/injective/mito-ui/pages/settings/allocator.vue")
  },
  {
    name: "settings-cpmm-allowance",
    path: "cpmm-allowance",
    component: () => import("/root/injective/mito-ui/pages/settings/cpmm-allowance.vue")
  },
  {
    name: "settings",
    path: "",
    component: () => import("/root/injective/mito-ui/pages/settings/index.vue")
  },
  {
    name: "settings-staking",
    path: "staking",
    component: () => import("/root/injective/mito-ui/pages/settings/staking.vue")
  }
]
  },
  {
    name: "swap",
    path: "/swap",
    component: () => import("/root/injective/mito-ui/pages/swap.vue")
  },
  {
    name: "terms-of-use",
    path: "/terms-of-use",
    meta: terms_45of_45usezCDOtTU1KrMeta || {},
    component: () => import("/root/injective/mito-ui/pages/terms-of-use.vue")
  },
  {
    name: _91vault_93gn0rprySalMeta?.name,
    path: "/vault/:vault()",
    component: () => import("/root/injective/mito-ui/pages/vault/[vault].vue"),
    children: [
  {
    name: activities8OpnPfBSn4Meta?.name,
    path: "activities",
    component: () => import("/root/injective/mito-ui/pages/vault/[vault]/activities.vue"),
    children: [
  {
    name: "vault-vault-activities",
    path: "",
    component: () => import("/root/injective/mito-ui/pages/vault/[vault]/activities/index.vue")
  },
  {
    name: "vault-vault-activities-orders",
    path: "orders",
    meta: ordersSo2Vnt1rcfMeta || {},
    component: () => import("/root/injective/mito-ui/pages/vault/[vault]/activities/orders.vue")
  },
  {
    name: "vault-vault-activities-trades",
    path: "trades",
    component: () => import("/root/injective/mito-ui/pages/vault/[vault]/activities/trades.vue")
  }
]
  },
  {
    name: "vault-vault-config",
    path: "config",
    component: () => import("/root/injective/mito-ui/pages/vault/[vault]/config.vue")
  },
  {
    name: "vault-vault",
    path: "",
    component: () => import("/root/injective/mito-ui/pages/vault/[vault]/index.vue")
  },
  {
    name: "vault-vault-info",
    path: "info",
    component: () => import("/root/injective/mito-ui/pages/vault/[vault]/info.vue")
  },
  {
    name: "vault-vault-liquidity",
    path: "liquidity",
    component: () => import("/root/injective/mito-ui/pages/vault/[vault]/liquidity.vue")
  },
  {
    name: "vault-vault-rewards",
    path: "rewards",
    component: () => import("/root/injective/mito-ui/pages/vault/[vault]/rewards.vue")
  }
]
  },
  {
    name: "vaults",
    path: "/vaults",
    component: () => import("/root/injective/mito-ui/pages/vaults.vue")
  },
  {
    name: "vote",
    path: "/vote",
    component: () => import("/root/injective/mito-ui/pages/vote.vue")
  },
  {
    name: "vaults",
    path: "/vaults",
    component: () => import("/root/injective/mito-ui/pages/vaults.vue")
  },
  {
    name: "home",
    path: "/home",
    component: () => import("/root/injective/mito-ui/pages/vaults.vue")
  }
]