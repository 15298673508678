import { MitoGaugeStatus } from '@injectivelabs/sdk-ts'
import {
  MainPages,
  MarketField,
  ManageSubPage,
  IDOProjectStep,
  CreateCpmmStep
} from '../../../types'

export default {
  manage: {
    title: 'New Launchpad',
    description: 'Create a launchpad in a few steps for your new project.',
    cta: 'Create launchpad',

    common: {
      nextStep: 'Next step'
    },

    [MainPages.Manage]: {
      title: 'Launchpad management'
    },
    [ManageSubPage.CreateCPMM]: {
      title: 'Vault Creation',
      nav: 'Create CPMM vault'
    },
    [ManageSubPage.CreateLaunchpad]: {
      title: 'Launchpad creation',
      nav: 'Create launchpad'
    },
    [ManageSubPage.Projects]: {
      title: 'Created launchpad',
      nav: 'View created launchpad'
    },
    [ManageSubPage.CreateMarket]: {
      title: 'Launch spot market',
      nav: 'Launch market'
    },
    [ManageSubPage.ManageGauge]: {
      title: 'Manage Gauges',
      nav: 'Gauges'
    },

    allowance: {
      title: 'Grant allowance',
      insufficientPermissionDescription:
        'Insufficient permission, please contract us for more information.',
      form: {
        header: 'Grant one time allowance',
        cta: 'Grant'
      },
      query: {
        title: 'Query',
        doesntExist: {
          title: "Doesn't exist",
          description: 'Allowance for {address} not found.'
        },
        exist: {
          title: 'Exist',
          description: 'Allowance for {address} has been granted.'
        }
      },
      toast: {
        successfullyGranted: 'Allowance granted successfully!'
      }
    },

    gauge: {
      [MitoGaugeStatus.Live]: 'Live',
      [MitoGaugeStatus.Active]: 'Future',
      title: 'Create gauge',
      id: 'ID',
      date: 'Date',
      noData: 'No gauges found',
      selectDate: 'Select date',
      remainingTime: 'Remaining time',
      addMoreFunds: 'Add more funds',
      successToast: 'Gauge created!',
      cta: 'Create'
    },

    market: {
      ratio: 'Ratio',
      title: 'Launch spot market',
      initialDeposit: 'Initial deposit',
      noCpmmVaultToast: 'No pre-existing CPMM vault found, please try again.',
      successToast: 'Successfully launched market',
      doesntExist:
        "Market for {baseSymbol} and {quoteSymbol} doesn't exist, launch the market {link}",

      advancedSettings: {
        title: 'Advanced Market Settings',
        parameterValues: 'Parameter Values:',
        relayerFeeShareRate: 'Relayer Fee Share Rate',
        minimalProtocolFeeRate: 'Minimal Protocol Fee Rate',
        formula:
          'takerFeeRate > (minimumProtocolFee - makerFeeRate) / (1 - relayerFeeShare)',
        description1:
          'The Maker Fee Rate and Taker Fee Rate fields are pre-populated with our recommended values to ensure optimal market conditions.',
        description2:
          'The relationship between the Maker Fee Rate and Taker Fee Rate must adhere to the following formula to be valid:',
        description3:
          'Recommendation: We strongly advise against modifying the Maker Fee Rate and Taker Fee Rate unless you are confident in maintaining the correct balance as per the formula. Incorrect adjustments may result in errors and prevent the creation of the market.'
      },

      [MarketField.Ticker]: {
        title: 'Ticker',
        placeholder: 'ETH/INJ'
      },

      [MarketField.BaseDenom]: {
        title: 'Base denom',
        placeholder: 'Select base token'
      },

      [MarketField.QuoteDenom]: {
        title: 'Quote denom',
        placeholder: 'Select quote token'
      },

      [MarketField.MinNotional]: {
        title: 'Min notional',
        placeholder: '0.001',
        description:
          'For example, USDT quoted markets is 1 USDT and INJ quoted markets is 0.01 INJ'
      },

      [MarketField.MakerFeeRate]: {
        title: 'Maker fee rate',
        placeholder: '-0.01',
        description:
          'Describes the target fee rate for makers. Makers create liquidity by setting orders that are not immediately filled.'
      },

      [MarketField.TakerFeeRate]: {
        title: 'Taker fee rate',
        placeholder: '0.2',
        description:
          'Describes the target fee rate for takers. Takers fill the orders created by the makers, buying or selling instantly.'
      },

      [MarketField.MinPriceTickSize]: {
        title: 'Min price tick size',
        placeholder: '0.001',
        description:
          'For example, INJ/USDT min. price tick size is 0.001. Thus, the INJ price in USDT has 3 decimal places'
      },

      [MarketField.MinQuantityTickSize]: {
        title: 'Min quantity tick size',
        placeholder: '0.001',
        description:
          'For example, INJ/USDT min. quantity tick size is 0.001. Thus the min amount to trade is 0.001 INJ'
      },

      [MarketField.ListingFee]: {
        title: 'Listing fee',
        description:
          'An instant listing fee of {fee} INJ is required. The fee is sent to the community spend pool. Please make sure all information provided is accurate before launching this market.'
      }
    }
  },
  manageLaunchpad: {
    description: 'Create a launchpad in a few steps',
    nav: {
      [IDOProjectStep.TokenInfo]: 'Token Info',
      [IDOProjectStep.CpmmStrategy]: 'CPMM Strategy',
      [IDOProjectStep.VestingSchedule]: 'Vesting schedule',
      // [IDOProjectStep.ProjectDetails]: 'Project Details',
      [IDOProjectStep.SalesDetails]: 'Sales Details',
      [IDOProjectStep.ReviewSummary]: 'Review Summary'
    }
  },

  manageCpmm: {
    summary: {
      title: 'Summary'
    },

    description:
      'Create a permissionless vault in a few steps ({step}/{total})',

    nav: {
      [CreateCpmmStep.Details]: 'Vault details',
      [CreateCpmmStep.Liquidity]: 'Price & initial liquidity',
      [CreateCpmmStep.Summary]: 'Summary & confirmation'
    },

    toast: {
      successfullyCreated: 'CPMM vault created successfully!'
    },

    liquidity: {
      tokenPrices: {
        title: 'Token prices',
        ratio: 'Ratio',
        description:
          'The token prices are used to calculate the ratio of the initial liquidity of the vault.',
        emptyPriceWarning: 'Please determine the price of the token {symbol}.'
      },
      initialLiquidity: {
        title: 'Initial Liquidity',
        deposit: {
          title: 'Deposit amount',
          description:
            'A minimum initial investment of {amount} {symbol} is required, though a higher initial investment is recommended.'
        }
      },
      creationFee: {
        title: 'Vault Creation Fee',
        description:
          "In addition to your initial liquidity amount, you'll need to have {amount} INJ available to cover the vault creation fee. This fee will be fully used to pay for gas for the smart contract vault calls."
      },
      insufficientFundsWarning:
        'A minimum of {amount} INJ is required in the initial funds.'
    }
  }
}
